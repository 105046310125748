<template>
  <v-col sm="1">
    <v-dialog v-model="dialog" max-width="70%" persistent>
      <v-card>
        <v-form ref="form" v-model="valid">
          <v-toolbar dark :color="$cv('principal')">
            <v-btn icon dark @click="$emit('terminar')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ action }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="save" :disabled="!valid">Guardar</v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="3" sm="12">
                  <v-text-field
                    v-model="editedItem.cantidad"
                    label="Cantidad"
                    autofocus
                    :rules="[
                      $rulesRequerido,
                      $rulesNumericos,
                      $rulesNumeroMinimo,
                    ]"
                    @keyup="updateSubtotal()"
                    @keyup.enter="save"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5" sm="12">
                  <v-text-field
                    v-model="editedItem.nombre"
                    label="Producto"
                    :readonly="readonly"
                    :rules="[$rulesRequerido]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <v-text-field
                    v-model="editedItem.precio"
                    label="Precio Unitario"
                    :readonly="readonly"
                    :rules="[$rulesRequerido, $rulesNumericos]"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" md="2" sm="12">
                  <v-text-field
                    v-model="editedItem.subtotal"
                    label="Subtotal"
                    :readonly="readOnlySubtotal ? true : false"
                    :rules="[$rulesRequerido, $rulesNumericos]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
      default: "",
    },
  },

  data() {
    return {
      editedItem: {
        nombre: "",
        id: "",
      },
      valid: true,
      dialog: true,
      readonly: true,
      readOnlySubtotal: true,
    };
  },

  computed: {
    ...mapState("carrito", ["carrito", "total"]),
  },

  methods: {
    ...mapMutations("carrito", ["addProducto", "editProducto"]),

    updateSubtotal(item = false) {
      if (item) {
        this.editedItem = Object.assign({}, item);
      }
      // Asegúrate de que la cantidad es un número válido
      if (!isNaN(this.editedItem.cantidad)) {
        // Calcula el nuevo subtotal
        this.readOnlySubtotal = false;
        this.editedItem.subtotal =
          this.editedItem.cantidad * this.editedItem.precio;
        this.readOnlySubtotal = true;
      }
    },
    save() {
      console.log(this.action);
      if (this.action == "AGREGAR PRODUCTO" || this.action == "AGREGAR ESTAMPILLAS") {
        if (this.editedItem.cantidad < 10 || this.editedItem.subtotal == 0)
          return;
        this.addProducto(this.editedItem);
        return this.$emit("terminar");
      }
      if (this.action == "EDITAR PRODUCTO" || this.action == "EDITAR ESTAMPILLAS") {
        if (this.editedItem.cantidad < 10) return;

        this.editProducto(this.editedItem);
        return this.$emit("terminar");
      }
    },
  },

  created() {
    this.editedItem = this.item;
    this.dialog = true;
  },
  mounted() {},

  destroyed() {},
};
</script>

<style scoped></style>
